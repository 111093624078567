.textContent {
  margin-left: 0.5rem;
  font-size: 1rem;
}

.textContentHeading {
  margin-left: 0.5rem;
  font-size: 2.7rem;
  font-weight: 800;
  line-height: 1.2;
}

.form-container {
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
}

form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.form-left,
.form-right {
  width: 100%;
}

.form-left {
  flex: 1;
}

.form-right {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f4f4f4;
}

.form-group.full-width input {
  width: 100%;
}

.submitButton {
  background-color: #0b4fb5; /* Theme color */
  color: white; /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #09408a; /* Darker shade for hover effect */
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .form-left {
    flex: 0.6;
    padding-right: 20px;
  }

  .form-right {
    flex: 0.4;
  }

  .form-group.full-width {
    grid-column: span 2;
  }
}
