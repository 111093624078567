.gridHomeCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.homePageGridContainer {
  margin: 4rem 10% 20px;
}

.iconGridContainer {
  /* margin: 1rem 20px; */
  display: flex;
}

.homePageAboutUsGridContainer {
  margin: 4rem 10% 100px;
}

.borderPageDivider {
  margin: 5rem 10%;
  border: 3px solid #bfbfbf;
}

.gridTextContainer {
  padding: 30px 0;
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

.gridTextAboutContainer {
  margin-left: 100px;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

.homeLoopholeHeaderTitle {
  font-size: 0.88rem;
  letter-spacing: 0.5px;
  margin-top: 0.75rem;
  font-weight: bolder;
  color: #080054;
}

.listingsCardPriceText {
  color: #080054;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin: 10px 5px;
}

.listingsCardPropertyDetails {
  color: #080054;
  margin: 5px 1rem;
  font-size: 0.8rem;
}

.listingsCardPropertyDetailsFontBold {
  font-weight: 700;
}

.homeLoopholeHeaderText {
  width: 90%;
  font-weight: bold;
  letter-spacing: 2.5px;
  margin: 0;
  font-size: 2.8rem;
  color: #080054;
}

.homeLoopholeMainText{
  width: 80%;
  margin: 40px 0px 30px;
}

.homeLoopholeDreamText {
  color: #17dafe;
}

.prospectiveHouseImage {
  border-radius: 40px;
  object-fit: cover;
  width: 90%;
  margin: 10% 10px; 
}

.viewListingsButton {
  border-radius: 10px;
  padding: 1rem 0.5rem;
  border: none;
  background-color: #ff6f00;
  
  text-align: center;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 20px 5px 10px 0;
}

.viewListingsButton span:after {
  color: white;
  content: '\00bb';
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.viewListingsButton:hover span:after {
  opacity: 1;
  right: 0;
}

.buttonFont {
  color: white;
  font-weight: 500;
  font-size: 1rem;
}

.iconContainer {
  display: grid;
  grid-template-columns: 30% auto;
  justify-items: center;
}

.iconTextContainer {
 margin: 2px 5px 0 5px; 
}

.iconTextHeading {
  font-size: 1.3rem;
  margin: 0;
}

.iconTextDetails {
  font-size: 0.7rem;
  margin: 5px 15px 5px 0;
}

.homeLoopholeAboutHeaderTitle {
  font-size: 0.88rem;
  letter-spacing: 0.5px;
  margin-top: 0.75rem;
  font-weight: bolder;
}

.homeLoopholeAboutHeaderText {
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
  font-size: 2.8rem;
}

.aboutUsButton {
  border-radius: 10px;
  padding: 1rem 0.5rem;
  border: none;
  background-color: #090154;
  width: 140px;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  margin: 20px 5px 10px 0;
}

.aboutUsButton span:after {
  color: white;
  content: '\00bb';
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.aboutUsButton:hover span:after {
  opacity: 1;
  right: 0;
}

.aboutUsbuttonFont {
  color: white;
  font-weight: 500;
  font-size: 1rem;
}

@media only screen and (max-width: 700px) {
  /* For mobile: */
  .homePageGridContainer {
    margin-top: 10px;
  }

  .homeLoopholeMainText{
    margin: 40px 0px 30px;
  }

  .gridTextContainer {
    margin: 0 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
  }

  .gridTextAboutContainer {
    margin-left: 1px;
    margin-top: 30px;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
  }

  .viewListingsButton {
    margin: 20px 5px 1rem 0;
  }
  
  /* .iconGridContainer {
    margin: 2rem 10% 3rem 5%;
  } */

  .iconContainer {
    padding: 20px 5px;
    display: grid;
    grid-template-columns: 30% auto;
    justify-items: center;
  }

  .borderPageDivider {
    margin: 3rem 10%;
    border: 2px solid #bfbfbf;
  }

  .iconTextContainer {
    margin: 5px 5px 0 0.5rem; 
   }
}

.carouselContainer {
  overflow-x: auto;
  margin-bottom: 20px; /* Adjust spacing as needed */
}

.carousel {
  display: flex;
  align-items: center;
  position: relative;
}

.cardWrapper {
  display: flex;
}

.navButton {
  margin: auto;
  padding: auto;
  background: none;
  border: none;
  max-height: 40px;
  font-size: 24px;
  cursor: pointer;
}

.navButton:hover {
  color: #000; /* Adjust color as needed */
}
