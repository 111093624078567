.textContent {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: lighter;
}

.textContentHeading {
  margin-left: 0.5rem;
  font-size: 2.7rem;
  font-weight: 800;
  line-height: 1.2;
}

.textContentSubHeading {
  font-size: 2rem;
}

.button {
  background-color: #0b4fb5; /* Theme color */
  color: white; /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}

.errorMessageContainer {
  margin-top: 32px;
}

.errorMessage {
  color: red;
  font-size: 18px;
  margin-bottom: 16px;
}

.refreshButton {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #0b4fb5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.noExpensesMessage {
  margin-top: 32px;
}

.tableContainer {
  margin-top: 32px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.tableContainer {
  margin-top: 16px;
}

.tableHeadRow {
  font-weight: bold;
  font-size: 16px;
}

.tableRow {
  padding: 8px 0;
}

.totalDifference {
  margin-top: 32px;
  font-size: 18px;
}

.feeContainer {
  width: 100%;
  padding: 5px 16px;
  border-top: 5px solid #cccccc;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.netDifferenceText {
  display: inline-block;
  background-color: #40fbff;
  font-size: 2rem;
  padding: 15px 10px;
  border-radius: 20px;
}

.tableHeading {
  font-size: 18px;
  font-weight: bold;
  padding: 8px;
  text-align: left;
  border-bottom: 5px solid #cccccc;
  border-radius: 10%;
}

.tableCell {
  padding: 8px;
}

.differenceContainer {
  margin-top: 32px;
}

.differenceText {
  font-size: 18px;
  font-weight: bold;
}

.addButtonContainer {
  margin-top: 32px;
}

.addButton {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #0b4fb5;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  border-radius: 15px;
}

.goBackButton {
  border: 1px solid #0b4fb5;
  padding: 8px 16px;
  font-size: 16px;
  border: 1px #0b4fb5 border;
  background-color: white;
  color: #0b4fb5;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  border-radius: 15px;
  margin: 0 10px;
}
