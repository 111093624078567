.iconGridContainer {
  /* margin: 1rem 20px; */
  display: flex;
  justify-content: space-between;
}

.navButton {
  margin: auto;
  padding: auto;
  background: none;
  border: none;
  max-height: 40px;
  font-size: 24px;
  cursor: pointer;
}

.navButton:hover {
  color: #000; /* Adjust color as needed */
}
