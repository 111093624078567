.modalContainer {
  width: 80%;
  height: 60%;
}

.showMoreContainer {
}

.moreDetailsTextContainer {
  padding: 20px;
  /* border: 1px solid rgb(194, 172, 172); */
}

.moreDetailsImageSliderContainer {
  padding: 20px;
  margin: 20px 5px;
  /* border: 1px solid rgb(194, 172, 172); */
}
