.textContent {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: lighter;
}

.textContentHeading {
  margin-left: 0.5rem;
  font-size: 2.7rem;
  font-weight: 800;
  line-height: 1.2;
}

.textContentHeadingPrice {
  display: inline-flex;
  border-radius: 10px;
  padding: 20px;
  margin-left: 0.5rem;
  font-size: 2.7rem;
  font-weight: 800;
  line-height: 1.2;
  background-color: #40fbff;
  margin: 15px 0 30px;
}

.addPropertyButton {
  background-color: #0b4fb5; /* Theme color */
  color: white; /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}

.addPropertyButton:hover {
  background-color: #09408a; /* Darker shade for hover effect */
}

/* Container for the list of properties */
.container {
  display: flex;
  flex-direction: column;
  gap: 24px; /* Adjust gap as needed */
  padding: 40px 10px;
  border-radius: 20px;
}

/* Each property item */
.propertyItem {
  position: relative;
}

/* Card styling */
.card {
  background-color: #f4f4f4; /* Grey background for the card */
  border: none; /* No border */
  box-shadow: none; /* No shadow */
  width: 100%;
  padding: 16px; /* Padding for the card */
  border-radius: 30px;
  font-size: 1.3rem;
  font-weight: 100;
}

/* Card content styling */
.cardContent {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space items out horizontally */
}

/* Text container styling */
.textContainer {
  display: flex;
  flex-direction: column; /* Align text vertically */
  gap: 8px; /* Space between text items */
}

/* Property ID and location styling */
.propertyId,
.propertyLocation {
  margin: 0; /* Remove default margin */
}

/* View button styling */
.viewButton {
  background-color: #0b4fb5; /* Blue background for the button */
  color: #ffffff; /* White text color */
  padding: 8px 16px; /* Adjust padding as needed */
  border: none; /* Remove default button border */
  border-radius: 50px; /* Large border radius */
  font-size: 16px; /* Adjust font size */
  text-decoration: none; /* Remove underline from link */
  display: inline-block; /* Ensure button behaves like inline element */
  cursor: pointer; /* Show pointer cursor on hover */
}

/* Delete button styling */
.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  color: #000; /* Change to desired color */
}

/* Optional: Add specific styling for delete icon if using Unicode */
.deleteIcon {
  font-size: 24px; /* Adjust size if needed */
}

.propertyDetailsContentContainer {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Spreads items evenly */
}

.propertyDetailsContentText {
  flex-basis: 20%; /* Takes up 20% of the width */
  margin: 0 10px; /* Adds 10px margin on each side */
  text-align: center; /* Centers the text horizontally */
  box-sizing: border-box; /* Ensures padding/margin doesn't affect width */
}

.buttonContainer {
  display: flex;
  gap: 20px; /* Space between the buttons */
}

.viewExpensesButton {
  background-color: #0b4fb5; /* Blue theme color */
  color: white; /* White text */
  border: none;
  border-radius: 8px; /* Rounded corners */
  padding: 10px 40px; /* Adjust padding as needed */
  cursor: pointer;
}

.viewExpensesButton:hover {
  background-color: #537bb7;
}

.deletePropertyButton {
  background-color: white; /* White background */
  color: red; /* Red text */
  border: 2px solid red; /* Red border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 40px; /* Adjust padding as needed */
  cursor: pointer;
}

.deletePropertyButton:hover {
  background-color: rgb(211, 160, 160); /* White background */
  color: red; /* Red text */
  border: 2px solid red; /* Red border */
}
