.childrenContent {
  border-radius: 35px;
  background-color: white;
  padding: 30px 20px;
  font-family: "Century Gothic", Arial, sans-serif;
}

/* Base styles */
.logo {
  color: white;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 10rem;
  height: 5rem; /* Default height */
  width: auto;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .logo {
    top: 10px; /* Reduce top margin for smaller screens */
    left: 10px; /* Reduce left margin for smaller screens */
    /* height: 40px; Smaller logo height for smaller screens */
    height: 5rem;
    width: 9rem;
  }
}

@media (max-width: 480px) {
  .logo {
    top: 5px; /* Further reduce top margin */
    left: 5px; /* Further reduce left margin */
    /* height: 30px; Even smaller logo height for very small screens */
    width: 7rem;
    height: 5rem;
  }
}

/* Ensure that the logo doesn't overlap with other content */
.childrenContent {
  margin-top: 60px; /* Add top margin to avoid overlap with the logo */
}
